<template>
    <el-container>
        <el-header class="reaHead">
            <div class="index-one" @click="homeClick">
                <el-image :src="require('@/assets/logo.png')" alt=""></el-image>
                <div class="index-title">会务管理系统</div>
            </div>
            <div class="newadd">
                <div style="position: relative; right: 30px; cursor: pointer" @click="homeClick">
                    <i class="el-icon-s-home" style="font-size: 30px"></i>
                </div>
                <div @mouseover="nameMouseover" @mouseleave="nameMouseleave">
                    <div class="index-name">
                        {{ nickName }}
                    </div>
                </div>
            </div>
        </el-header>
        <!-- 点击顶部名称退出框 -->
        <transition name="el-zoom-in-top">
            <div v-show="show2" class="transition-box" @mouseleave="nameMouseleave" @mouseover="nameMouseover">
                <div class="index-setting">手机号：{{ phone }}</div>
              <div class="index-setting" @click="editPerson">
                <i class="el-icon-user"></i>修改个人信息
              </div>
                <div class="index-setting" @click="editPassword">
                    <i class="el-icon-unlock"></i>修改密码
                </div>
                <div class="index-setting" @click="Privacypolicy">隐私政策</div>
                <div class="index-baby" @click="logout">退出登录</div>
            </div>
        </transition>
        <!-- 修改密码 -->
        <el-dialog title="修改密码" :visible.sync="EditPassdialogVisible" width="400px" :before-close="EditPassdialogVisibleClose">
            <!-- 不是初次登陆直接修改密码 -->
            <el-form :model="EditPassDate" :inline="true" label-width="120px" ref="EditPassDateRef" :rules="EditPassDateRules">
                <el-form-item label="用户名：">
                    <el-input v-model="nickName" disabled></el-input>
                </el-form-item>
                <el-form-item label="原密码：" prop="oldPassword">
                    <el-input type="password" v-model="EditPassDate.oldPassword" placeholder="请输入原密码"></el-input>
                </el-form-item>
                <el-form-item label="新密码：" prop="newPassword">
                    <el-input type="password" v-model="EditPassDate.newPassword" placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="请重复密码：" prop="newPasswordd">
                    <el-input type="password" v-model="EditPassDate.newPasswordd" placeholder="请输入重复密码"></el-input>
                </el-form-item>
              <el-form-item class="depar-dia btnCen">
                    <div class="">
                        <el-button type="primary" @click="editPassSave">保存</el-button>
                    </div>

                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 点击隐私政策弹出框 -->
        <el-dialog title="隐私政策" :visible.sync="PrivacydialogVisible" width="575px">
            <div>
                Cookie具有许多用途，例如：Cookie可以记住您的登录凭据，这样您就不必在每次登录服务时都输入这些凭据。Cookie有助于我们查看您访问的是哪些页面和功能，以及在这些页面上花费的时间。了解此类信息后，我们就能更有效地改善服务并为您提供更好的体验。
                访问和使用我们的服务，即表示您同意在您的设备上存储Cookie、其他本地存储技术、信标和其他信息；同意我们访问此类
                Cookie、本地存储技术、信标和信息。
                个人信息的保护会务管理系统充分尊重您的隐私权，并且不遗余力保护您的个人信息。通常情况下，您不需要提供任何个人信息就可以浏览会务管理系统的网站。为特定的目的，如果您以自愿填写的方式提供注册或订阅电子信息所需的姓名、性别、证件种类及证件号、出生年月日、国家、电子邮件信箱、电话、联系地址和邮政编码、所希望提供的服务或喜好信息、客户代码以及其他类似的个人信息，则表示您已经了解并接受您个人信息的用途，同意会务管理系统为实现该特定目的使用您的个人信息。会务管理系统承诺在任何时候、任何情况下都不会出售您的个人信息，会务管理系统只会在法律允许的范围内使用根据本条款获得的信息。但会务管理系统可能会根据法律、政府部门的要求向这些部门提供您某些个人信息；或在会务管理系统有理由认为有必要这样做来保护会务管理系统、客户或公众时，也可能在尽可能小的范围内公开某些个人信息，在您提供个人信息的时候应该已经预见并同意这种情况的发生。
                适用法律和管辖权
                通过访问本网站及使用通过本网站网址提供的设施和（或）服务,
                即表示您同意该访问及该实施和/或服务的提供受中华人民共和国法律的约束，且您同意受中华人民共和国法院的管辖。
            </div>
        </el-dialog>

      <el-dialog title="修改个人信息" :visible.sync="AdddialogVisible" width="380px" top="1vh" :before-close="AdddialogVisibleClose">
        <el-form :model="AddDateModel" ref="AddDateRef" :rules="AddDateRules" label-width="100px">

          <el-form-item label="手机号：" prop="phone">
            <el-input v-model="AddDateModel.phone" placeholder="请填写手机号"></el-input>
          </el-form-item>

          <el-form-item label="姓名：" prop="nickName">
            <el-input v-model="AddDateModel.nickName" placeholder="请填写姓名"></el-input>
          </el-form-item>

          <el-form-item label="邮箱：" prop="email">
            <el-input v-model="AddDateModel.email" placeholder="请填写邮箱"></el-input>
          </el-form-item>

          <el-form-item label="性别：" prop="gender">
            <el-radio-group v-model="AddDateModel.gender">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item class="depar-dia btnCen">
            <el-button v-if="addDepClick" type="primary" :loading="addDepClickKing" @click="parmen">修改</el-button>
            <el-button v-else type="info" :loading="addDepClickKing"></el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
export default {
    data() {
      var checkAge = (rule, value, callback) => {
        if (!value && String(value) !== "0") {
          return callback(new Error("不能为空"));
        }
        setTimeout(() => {
          if (!/^1[3|4|5|7|8][0-9]\d{8}$/.test(value)) {
            callback(new Error("请输入正确的手机号"));
          } else {
            callback();
          }
        }, 100);
      };
      var checkEmail = (rule, value, cb) => {
        // 验证邮箱的正则表达式
        const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
        if (regEmail.test(value)) {
          //合法的邮箱
          return cb()
        }
        cb(new Error('请输入合法的邮箱'))
      }
        return {
          addDepClick: true,
          addDepClickKing: false,
          AdddialogVisible:false,
            // 医生管理员还是科室管理员
            administrators: "",
            // 手机号
            phone: window.sessionStorage.getItem("phone"),
            type: window.sessionStorage.getItem("type"),
            nickName: window.sessionStorage.getItem("nickName"),
            roleName: window.sessionStorage.getItem("roleName"),
            // 鼠标移入名称
            show2: false,
            // 修改密码弹出框
            EditPassdialogVisible: false,
            // 隐私政策弹出框
            PrivacydialogVisible: false,
            // 修改密码数据
            EditPassDate: {
                oldPassword: "",
                newPassword: "",
                newPasswordd: "",
            },
            // 修改密码正则
            EditPassDateRules: {
                oldPassword: [
                    {
                        required: true,
                        message: "请输入原密码",
                        trigger: "blur",
                    },
                ],
                newPassword: [
                    {
                        required: true,
                        message: "请输入新密码",
                        trigger: "blur",
                    },
                ],
                newPasswordd: [
                    {
                        required: true,
                        message: "请重新输入新密码",
                        trigger: "blur",
                    },
                ],
            },
            // 鼠标移入移除标志
            mouseBiaozhi: "",
          AddDateModel: {
            userId:'',
            nickName:'',
            phone:"",
            email:"",
            gender:"",
          },
          AddDateRules: {
            nickName: [
              { required: true, message: "请填写姓名", trigger: "blur" },
            ],
            phone: [
              { required: true,validator: checkAge,trigger: "blur" },
            ],
            email: [
              { required: true, message: '请输入邮箱', trigger: 'blur' },
              { validator: checkEmail, trigger: 'blur' },
            ],
            gender: [
              { required: true, message: "请选择性别", trigger: "change" },
            ],
          },
        };
    },
    created() {
        this.winty();
        this.isFirs();
    },
    methods: {
      parmen() {
          this.editMeet();
      },
      // 点击修改科室用户管理的添加
      editMeet() {
        this.$refs.AddDateRef.validate(async (valid) => {
          if (!valid) return;
          this.addDepClick = false;
          this.addDepClickKing = true;
          let data = _qs.stringify({
            userId:this.AddDateModel.userId,
            email:this.AddDateModel.email,
            phone:this.AddDateModel.phone,
            nickName:this.AddDateModel.nickName,
            gender:this.AddDateModel.gender
          });
          let res = await this.$axios.updateProfile(data);
          // console.log(res);
          this.addDepClick = true;
          this.addDepClickKing = false;
          if (res.data.code == 401) {
            this.$router.push("/login");
          } else if (res.status == 200) {
            this.AdddialogVisibleClose();
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            window.sessionStorage.setItem("phone", res.data.data.phone);
            window.sessionStorage.setItem("userId", res.data.data.userId);
            window.sessionStorage.setItem("email", res.data.data.email);
            window.sessionStorage.setItem("nickName", res.data.data.nickName);
            window.sessionStorage.setItem("gender", res.data.data.gender);
            location.reload();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      },
      AdddialogVisibleClose(){
        this.AdddialogVisible=false
        this.AddDateModel= {
          userId:'',
          nickName:'',
          phone:"",
          email:"",
          gender:"",
        }
      },
        EditPassdialogVisibleClose() {
            this.$nextTick(() => {
                if (this.isFirst) {
                    this.$refs.EditFirstPassDateRef.clearValidate();
                } else {
                    this.$refs.EditPassDateRef.clearValidate();
                }
            });

            this.EditPassdialogVisible = false;
          this.EditPassDate= {
            oldPassword: "",
                newPassword: "",
                newPasswordd: "",
          }
        },
        winty() {
            if (window.sessionStorage.getItem("departmentId") !== "null") {
                this.administrators = "科室";
                this.isDoctore = false;
            } else {
                this.administrators = "医院";
                this.isDoctore = true;
            }
        },
        //是否是第一次登录 0 是  1 不是
        isFirs() {
            if (window.sessionStorage.getItem("isFirst") == 0) {
                this.isFirst = true;
            } else {
                this.isFirst = false;
            }
        },
        // 点击小房子图标
        homeClick() {
            this.$router.push("/index");
        },
        // 鼠标移入名称
        nameMouseover() {
            this.show2 = true;
        },
        nameMouseleave() {
            this.show2 = false;
        },
      editPerson(){
        this.AdddialogVisible=true
        this.AddDateModel.userId=window.sessionStorage.getItem("userId")
        this.AddDateModel.nickName=window.sessionStorage.getItem("nickName")
        this.AddDateModel.phone=window.sessionStorage.getItem("phone")
        this.AddDateModel.email=window.sessionStorage.getItem("email")
        this.AddDateModel.gender=window.sessionStorage.getItem("gender")

      },
        // 修改密码
        editPassword() {
            this.EditPassdialogVisible = true;
        },
        // 点击隐私政策
        Privacypolicy() {
            this.PrivacydialogVisible = true;
        },
        //退出登录
        async logout() {
            let {data:res}  = await this.$axios.conferenceLogout();
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
            } else if (res.code == 200) {
                this.$message({
                    message: res.msg,
                    type: "success",
                });
                window.sessionStorage.clear();
                window.localStorage.clear();
                this.$router.push("/login");
            }
        },
        // 点击修改密码的保存
        editPassSave() {
            this.$refs.EditPassDateRef.validate(async (valid) => {
                if (valid) {
                    if (
                        this.EditPassDate.newPassword ===
                        this.EditPassDate.newPasswordd
                    ) {
                        let data = _qs.stringify({
                          oldPass: this.EditPassDate.oldPassword,
                          newPass: this.EditPassDate.newPassword,
                        });
                        let {data:res} = await this.$axios.conferenceUpdatePass(data);
                        // console.log(res);
                        if (res.code == 401) {
                            this.$router.push("/login");
                        } else if (res.code == 500) {
                            this.$message({
                                message: res.msg,
                                type: "error",
                            });
                        } else if (res.code == 200) {
                            this.EditPassdialogVisible = false;
                            this.$message({
                                message: res.msg,
                                type: "success",
                            });
                          window.sessionStorage.clear();
                          window.localStorage.clear();
                            this.$router.push("/login");
                        }
                    } else {
                        this.$message({
                            message: "新密码不一致",
                            type: "error",
                        });
                    }
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .reaHead {
    position: relative;
    width: 100%;
    height: 80px !important;
    display: flex;
    align-items: center;
    //background: rgba(0, 0, 0, 0.6);
  background:#1b63c1 !important;
    color: white;
    justify-content: space-between;
    .index-title {
        font-size: 30px;
    }
    .index-one {
        display: flex;
        position: relative;
        align-items: center;
    }
    .index-one:hover {
        cursor: pointer;
    }

    .el-image__inner {
        width: 55%;
    }
    /deep/ .el-input--prefix .el-input__inner {
        border-radius: 20px;
    }
    .index-two {
        margin-left: 50px;
        margin-right: 50px;
    }
    .icon-shujukanban {
        font-size: 24px;
        margin-right: 30px;
    }
}
/deep/ .el-input__inner,/deep/ .el-form-item__content {
  width: 220px !important;
}

.newadd {
    display: flex;
    align-items: center;
}

.index-name {
    cursor: pointer;
    padding: 20px 0;
}

.transition-box {
    text-align: center;
    background: white;
    height: 220px;
    position: absolute;
    right: 10px;
    top: 59px;
    width: 180px;
    padding: 30px;
    font-size: 14px;
    box-shadow: 2px 1px 6px 0px #ccc;
    color: black;
    z-index: 9;
    .index-baby {
        cursor: pointer;
    }
    .index-setting {
        cursor: pointer;
        border-bottom: 1px solid #ccc;
        margin-bottom: 15px;
        padding-bottom: 10px;
        .el-icon-unlock,.el-icon-user,
        .el-icon-user-solid {
            margin-right: 10px;
        }
    }
    > .el-image {
        width: 50px;
        height: 50px;
    }
}
.depar-dia {
    width: 100%;
}

.btnCen {
  text-align: center;
    button {
        width: 150px;
    }
  /deep/ .el-form-item__content{
    width: 100%!important;
    margin-left: 0px!important;
  }
}
</style>
